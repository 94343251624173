<template>
  <div class="our-features-card">
    <div class="flex flex-col">
      <img class="icon" :src="getImageUrl('icon/how_to_reserve',icon)" alt=""/>
      <div>{{ title }}</div>
      <div>{{ content }}</div>
    </div>
    <img class="img" :src="getImageUrl('icon/how_to_reserve',icon_big)" alt=""/>
  </div>
</template>

<script setup lang="ts">
import { useImageUrl } from '~/composables/useImageUrl';

const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  icon_big: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  content: {
    type: String,
    default: ''
  }
});
const getImageUrl = useImageUrl;

/*
vite 舊方法
const iconSrc = computed(() => `~/assets/image/icon/how_to_reserve/${props.icon}`);
const iconBigSrc = computed(() => new URL(`~/assets/image/icon/how_to_reserve/${props.icon_big}`, import.meta.url).href);
*/

</script>

<style scoped lang="scss">
.our-features-card {
  @apply flex justify-between w-[352px] h-[175px] bg-osn-yellow-00 rounded-[20px] mr-[14px] px-[16px] py-[16px] text-osn-dark-grey-00 shadow
  mobile:w-full mobile:mx-[16px] mobile:mb-[14px];
  .icon {
    @apply w-[30px];
  }

  .img {
    @apply w-[143px] ml-[9px];
  }

  div:nth-child(2) {
    @apply text-[24px] font-bold italic mt-[28px] mb-[5px];
  }

  div:nth-child(3) {
    @apply text-[14px];
  }
}
</style>
