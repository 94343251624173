<template>
  <div class="pc:mx-auto pc:px-[140px] pc:max-w-[1366px]">
    <div class="flex justify-between items-center mobile:px-4">
      <div class="inline-flex items-center">
        <h2 class="text-[24px] font-bold mr-[6px]">{{title }}</h2>
        <svg-icon :name="iconName"
                  size="37"></svg-icon>
      </div>


      <!-- 查看更多-->
            <div v-if="isMore" class="inline-flex items-center cursor-pointer"
                 @click="moreSearch">
              <span class="mr-[7px] text-[16px] underline">{{ $t('sub_title.see_more') }}</span>
              <svg-icon name="arrow_circle_right" size="24"></svg-icon>
            </div>

    </div>


    <div class="mt-[20px]">
<!--      <spaceCard v-for="item in spaceCardList"
                 :space-card-obj="item"></spaceCard>-->

      <swiper :modules="[SwiperPagination]"
              :breakpoints="swiperBreakpoints"
              :pagination="isMobile">
        <swiper-slide v-for="item in spaceCardList"
                      :key="item.id">
          <spaceCard :space-card-obj="item"></spaceCard>
        </swiper-slide>
      </swiper>
    </div>

  </div>
</template>
<script setup>
import {ref} from 'vue'
import spaceCard from './SpaceCard.vue'
import {computed} from "vue";
import {useSettingStore} from "@/stores/setting.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {useSearchWithStore} from "@/composables/useSearchWithStore";


// import {Swiper, SwiperSlide} from 'nuxt-swiper';
// import {Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import {useGoTo} from "@/composables/useGoTo";

const settingStore = useSettingStore()
const searchQueryStore = useSearchQueryStore()
const isMobile = computed(() => settingStore.isMobile);
const {handleSearchWithStore} = useSearchWithStore()
const {go} = useGoTo()

const swiperBreakpoints = ref({
  // 手機版
  1: {
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 8,
  },
  // pc版
  768: {
    slidesPerView: 4,
    centeredSlides: false,
    spaceBetween: 14,
  },

})


const props = defineProps({
  title:{
    type: String,
    default: ''
  },
  iconName:{
    type: String,
    default: ''
  },
  spaceCardList: {
    type: Array,
    default: []
  },
  isMore:{
    type: Boolean,
    default: false
  },
  moreSearchParam: {
    type: Object,
    default: {}
  }
})


function moreSearch() {
  searchQueryStore.initQueryDate()
  searchQueryStore.setPagination(props.moreSearchParam.pagination)
  handleSearchWithStore()
  go('/search-result')
}


</script>
<style scoped
       lang="scss">
:deep(.swiper-pagination-bullet-active){
  @apply bg-osn-yellow-00;
}

</style>
