<template>
  <div class="space-banner-wrap">
    <div v-if="bannerList.length > 0"
         class="banner-wrap">
      <swiper :slides-per-view="isMobile ? 1.2 : 3"
              :centeredSlides="isMobile"
              :space-between="isMobile ? 8 : 12"
              @swiper="onSwiper">
        <swiper-slide v-for="(banner) in bannerList" :key="banner">
          <div class="slide-wrap"
               @click="handleBannerClick(banner)">
            <img :src="picUrl + banner.photo_url" alt="">
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <el-skeleton v-else
                 class="flex w-full min-h-[200px]"
                 :throttle="500"
                 :loading="true"
                 animated>
      <template #template>
        <el-skeleton-item v-for="it in 3"
                          variant="image"
                          class="flex-1 min-h-[200px] mx-2"/>
      </template>
    </el-skeleton>

    <div v-if="!isMobile && bannerList.length > 3"
         class="absolute top-1/2 translate-y-[-50%] left-[105px] cursor-pointer z-20" @click="handleScroll(-360)">
      <svg-icon name="space_type_prev"
                size="50"></svg-icon>
    </div>
    <div v-if="!isMobile && bannerList.length > 3"
         class="absolute top-1/2 translate-y-[-50%] right-[105px] cursor-pointer z-20" @click="handleScroll(360)">
      <svg-icon name="space_type_next"
                size="50"></svg-icon>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay} from "swiper/modules";
import {bannerDetail, banners} from "@/api/banner";
import {useSearchWithStore} from "@/composables/useSearchWithStore";
import {useGoTo} from "@/composables/useGoTo";
import {useRouter} from "nuxt/app";
import {useSettingStore} from "@/stores/setting";
import {useSearchQueryStore} from "@/stores/searchQuery";

const {go, toWebsite} = useGoTo();
const router = useRouter();
const {handleSearchWithStore} = useSearchWithStore()
const searchQueryStore = useSearchQueryStore()
const settingStore = useSettingStore()

const picUrl = ref(import.meta.env.VITE_APP_BASE_IMG_URL)

const refBannerSwiper = ref(null)
const bannerList = ref([])

// const isMobile = computed(() => settingStore.isMobile);
const {isMobile} = useDevice();

onMounted(async () => {
  const res = await banners()
  bannerList.value = res.list || []
})

function onSwiper(swiper) {
  refBannerSwiper.value = swiper
}

function handleScroll(distance) {
  nextTick(() => {
    if (distance > 0) {
      refBannerSwiper.value?.slideNext()
    } else {
      refBannerSwiper.value?.slidePrev()
    }
  });
}

async function handleBannerClick(banner) {
  switch (banner.type) {
    case 1:
      router.push({path: 'banner/'+banner.id});
      break;
    case 2:
      const res2 = await bannerDetail(banner.id)
      toWebsite(res2.data1)
      break;
    case 3:
      const res3 = await bannerDetail(banner.id)
      const searchJsonObj = JSON.parse(res3.data1)
      /// 清掉原本條件
      searchQueryStore.initQueryDate()
      searchQueryStore.setSelectedCityID(searchJsonObj.filter_options.city_ids)
      searchQueryStore.setSelectedCategoryObject(searchJsonObj.filter_options.category_ids)
      /// 搜尋
      handleSearchWithStore()
      /// go
      go('/search-result')
      break;
  }
}

</script>

<style scoped lang="scss">

.space-banner-wrap {
  @apply relative mx-auto min-h-[200px]
  pc:px-[130px] pc:mt-[-36px] pc:max-w-[1366px]
  mobile:mt-[20px];

  .banner-wrap {
    .slide-wrap {
      @apply relative cursor-pointer;
      //w-[360px] h-[200px];
      img {
        @apply rounded-[20px] h-[200px] object-cover aspect-[360/200];
      }

      span {
        @apply absolute left-[20px] bottom-[20px] right-[20px]
        text-[20px] text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)];
      }
    }
  }
}
</style>
