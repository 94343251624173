<template>
  <div class="pc:mt-[26px] mobile:mt-[13px] flex mobile:flex-col">
    <div class="login-btn pc:mr-[12px] text-osn-black-00 bg-osn-yellow-00"
         @click="handelLoginBtn">{{ $t('footer.user_login') }}
    </div>
    <div class="login-btn text-osn-yellow-00 bg-osn-dark-grey-00"
         @click="goToBackStage">{{ $t('footer.owner_login') }}
    </div>
  </div>
</template>
<script setup>
import {useGoTo} from "@/composables/useGoTo";
import {ref} from "vue";

const { $fbq } = useNuxtApp()
const hostUrl = ref(import.meta.env.VITE_APP_BASE_HOST_URL)

const {toWebsite, handelLoginBtn} = useGoTo()

function goToBackStage() {
  $fbq('trackCustom', 'OSHostPotentialCustomers')
  toWebsite(hostUrl.value)

}
</script>
<style scoped
       lang="scss">
div.login-btn {
  @apply text-[15px] font-semibold rounded-full cursor-pointer
  pc:px-[12px] pc:h-[50px] pc:leading-[50px]
  mobile:mb-2 mobile:w-[187px] mobile:h-[40px] mobile:leading-[40px]  mobile:text-center;
}

</style>
