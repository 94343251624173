<template>
  <div class="pc:mx-auto pc:px-[140px] pc:max-w-[1366px] mobile:px-4">
    <h1 class="text-[24px] font-bold">{{ $t('sub_title.usage_search') }}</h1>
    <div class="top-card-list">
      <searchCard v-for="item in searchCardList"
                  :name="item.name"
                  :img-name="getCategoryImageFileName(item.id)"
                  :search-id="item.id"
                  :search-store="setSelectedCategoryInStore"></searchCard>
    </div>
    <div class="unit-list ">
      <div class="mt-[30px]"
           v-for="item in unitList">
        <searchUnitList
            :title="item.name"
            :unit-list="item.info"
            :search-store="setSelectedCategoryInStore"></searchUnitList>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, watchEffect} from 'vue'
import searchCard from './SearchCard.vue'
import searchUnitList from "./SearchUnitList.vue";
import {useCategoryStore} from "@/stores/category.js";
import {useSearchQueryStore} from "@/stores/searchQuery.js";
import {getCategoryImageFileName} from "@/utils/parseTool.js";

const categoryStore = useCategoryStore()
const searchQueryStore = useSearchQueryStore()
const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID.toString()

const searchCardList = ref([])
const unitList = ref([])


watchEffect(() => {
  const usageList = categoryStore.getUsageCategories

  if (usageList) {

    searchCardList.value = usageList.slice(0, 4)
    unitList.value = [{
      name: '',
      info: usageList.slice(4)
    }]
  }
})

function setSelectedCategoryInStore(categoryIDList = []) {
  searchQueryStore.setSelectedCategoryObject({
    [usageCategoryId]: categoryIDList
  });

}

</script>

<style scoped
       lang="scss">

.top-card-list {
  @apply mt-[20px] w-[100%] flex
  mobile:flex-wrap;
  >div {
    @apply basis-[calc(50%-4px)] pc:mr-[8px];
  }
  >div:nth-child(odd) {
    @apply mobile:mr-[8px];
  }
}

.unit-list {
  @apply flex justify-between flex-wrap;
  >div {
    @apply mt-[30px]
    mobile:w-full;
  }
}

</style>
